<!--
--------------------------------------------------------------------------
   PersonalCornerProjects.vue
--------------------------------------------------------------------------

    Component to edit projects data

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner-projects-data" class="container">
        <loading v-if="loading"></loading>
        <div class="row">
            <div class="col">
                <p v-translate>List of projects of which you are a coordinator and you can complete their data:</p>
                <!-- Projects found -->
                <div id="items" v-if="!loading && projects.length > 0">
                    <ul class="list">
                        <b-media tag="li" right-align vertical-align="center" v-for="(item, index) in projects" :key="'item.' + item.id" :id="'item-' + item.id" :class="'item-' + item.type + '-' + item.subtype">
                            <template #aside v-if="getItemFieldValue(item, 'image', $language.current, false) !== null && imagesLoaded[index]">
                                <router-link :to="getItemURL(item).replace($store.state.config.URL, '')" target="_blank">
                                    <b-img-lazy :src="getItemFieldValue(item, 'image', $language.current, false).startsWith('http') ? getItemFieldValue(item, 'image', $language.current, false) : $store.state.config.URL + '/images/items/' + getItemFieldValue(item, 'image', $language.current, false)" :alt="getItemFieldValue(item, 'title', $language.current, false)" :title="getItemFieldValue(item, 'title', $language.current, false)" @error.native="hideImage(index)" :class="item.type"></b-img-lazy>
                                </router-link>
                            </template>
                            <h5 class="mt-0">
                                <router-link :to="getItemURL(item).replace($store.state.config.URL, '')" target="_blank">{{ getItemFieldValue(item, 'title', $language.current, false) }}</router-link>
                                <i class="fas fa-lock-open open-access" v-if="getItemFieldValue(item, 'openAccess', $language.current, false) === '1'"></i>
                            </h5>
                            <p v-html="printItemDescription(item, null)"></p>
                            <div class="item-list-tools">
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a href="javascript:void(0);" :title="'Complete project data'|translate" @click="editProject(item)"><img src="/images/tools/abstract.png" :alt="'Complete project data'|translate"></a>&nbsp;<a href="javascript:void(0);" :title="'Complete project data'|translate" @click="editProject(item)"><translate>Complete project data</translate></a>
                                    </li>
                                </ul>
                            </div>
                        </b-media>
                    </ul>
                </div>

                <!-- Not found error -->
                <b-alert show variant="danger" class="mt-4" v-if="!loading && projects.length === 0">
                    <translate>No project of which you are coordinator has been found.</translate>
                </b-alert>

                <!-- Modal to edit project data -->
                <b-modal id="edit-project" :title="'Complete project data'|translate" ok-only :ok-title="'Close'|translate" size="xl">

                    <template #default>
                        <b-form @submit.stop.prevent="submitProjectData" novalidate class="mt-4" ref="form-edit-project">

                            <b-form-group id="group-image-file" :label="'Image'|translate" label-for="imageFile" :class="{ 'mb-1' : formData.image != null, 'mb-3' : formData.image == null}" :description="$gettext('Select an image file from your hard drive to include in the page of the project, or check the box to delete the current image. The file must be in JPG or PNG format, and the size should be 120 x 120 pixels.')">
                                <b-form-file v-model="imageFile" :placeholder="$gettext('Choose an image...')" @input="uploadFile" ref="imageInput"></b-form-file>
                            </b-form-group>

                            <b-form-checkbox v-model="formData.deleteImage" value="1" unchecked-value="0" class="mb-3" v-if="formData.image != null">
                                <translate>Delete current image</translate> (<a :href="$store.state.config.URL + '/images/items/' + formData.image" target="_blank">{{ formData.image }}</a>)
                            </b-form-checkbox>

                            <b-form-group id="group-description" :label="$gettext('Description') + ' (' + name + ')'" :label-for="'description.' + key" :key="'description.' + key" class="mb-3" v-for="(name, key) in $language.available">
                                <b-form-textarea :id="'description.' + key" :name="'description.' + key" v-model="$v.formData.description[key].$model" rows="3" :state="validateState('description', key)"></b-form-textarea>
                                <b-form-invalid-feedback :id="'description.' + key"><translate>Description is required in all languages.</translate></b-form-invalid-feedback>
                            </b-form-group>


                            <b-form-group id="group-collaborative-network" :label="'Collaborative networks'|translate" label-for="collaborativeNetwork" class="mb-3" :description="$gettext('Enter links for the collaborative networks to which your project belongs. Select the network from the drop-down list and enter the link to your project\'s profile or page on the selected network in the text box.')">
                                <b-input-group v-for="(v, index) in $v.formData.collaborativeNetwork.$each.$iter" :key="'collaborativeNetwork.' + index" class="mb-2">
                                    <b-input-group-prepend>
                                        <b-form-select v-model="v.name.$model" style="width: 200px;">
                                            <b-form-select-option value="">{{ $gettext('-- Select a network --')}}</b-form-select-option>
                                            <b-form-select-option :value="collaborativeNetwork.name" v-for="(collaborativeNetwork, index2) in collaborativeNetworks" :key="'collaborativeNetwork.' + index + '.' + index2">{{ collaborativeNetwork.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-input-group-prepend>
                                    <b-form-input v-model="v.link.$model" placeholder="http://www.example.com/user"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="success" @click="addCollaborativeNetwork()"><i class="fas fa-plus"></i></b-button>
                                        <b-button variant="danger" @click="deleteCollaborativeNetwork(index)" :disabled="formData.collaborativeNetwork.length === 1"><i class="fas fa-minus"></i></b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>

                            <b-form-group id="group-custom-link" :label="'Links of interest'|translate" label-for="customLink" class="mb-3" :description="$gettext('Enter the links of interest that you would like to include on your project\'s page.')">
                                <b-input-group v-for="(v, index) in $v.formData.customLink.$each.$iter" :key="'customLink.' + index" class="mb-2">
                                    <b-input-group-prepend>
                                        <b-form-input v-model="v.title.$model" :placeholder="$gettext('Title (optional)')"></b-form-input>
                                    </b-input-group-prepend>
                                    <b-form-input v-model="v.link.$model" placeholder="http://www.example.com"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="success" @click="addCustomLink()"><i class="fas fa-plus"></i></b-button>
                                        <b-button variant="danger" @click="deleteCustomLink(index)" :disabled="formData.customLink.length === 1"><i class="fas fa-minus"></i></b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-form>
                    </template>

                    <template #modal-footer="{ cancel }">
                        <b-button type="submit" variant="primary" @click="submitProjectData"><translate>Save</translate></b-button>&nbsp;
                        <b-button variant="secondary" @click="cancel"><translate>Close</translate></b-button>
                    </template>

                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';
    import { requiredIf } from "vuelidate/lib/validators";
    import Loading from '@/components/Loading';

    export default {
        name: 'personal-corner-projects-data',
        props: ['item'],
        mixins: [validationMixin],
        components: { Loading },
        data() {
            let componentData = {
                loading: false,
                projects: [],
                imagesLoaded: [],
                searchData: {
                    query: '+subtype:participacioProjecteRDI* +activity_ca_person_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false)) + '##' + this.item.id + '##coordinadorCientific*',
                    facets: [],
                    sort: 'activity_ca_date_sort asc',
                    page: {
                        pageIdx: 0,
                        pageSize: 500
                    }
                },
                dataLoaded: false,
                collaborativeNetworks: [],
                imageFile: null,
                formData: {
                    collaborativeNetwork: [],
                    customLink: [],
                    image: null,
                    deleteImage: 0,
                    description: {}
                },
                project: null
            };

            // Prepare fields with multiple languages
            Object.keys(this.$language.available).forEach(l => {
                componentData.formData.description[l] = '';
            });

            return componentData;
        },
        validations() {
            let rules = {
                formData: {
                    collaborativeNetwork: {$each: { name: {}, link: {} }},
                    customLink: {$each: { title: {}, link: {} }},
                    description: {}
                }
            };

            // Prepare validation for fields with multiple languages
            Object.keys(this.$language.available).forEach(l => {
                rules.formData.description[l] = {
                    required: requiredIf(function() {
                        let require = false;

                        // Description is required in all languages
                        Object.keys(this.$language.available).forEach(la => {
                            if (la !== l && this.formData.description[la] != '') require = true;
                        });

                        return require;
                    })
                };
            });

            return rules;
        },
        mounted() {
            // Get projects
            this.getProjects();
        },
        methods: {
            /**
             * Get projects
             */
            getProjects() {
                // Set loading
                this.loading = true;

                // Search items
                this.axios.post(this.apiURL + '/items/search', this.searchData)
                    .then(response => {
                        this.loading = false;

                        // Get items in response
                        let projects = [];
                        this.imagesLoaded = [];
                        response.data.content.forEach(item => {
                            projects.push(item);
                            this.imagesLoaded.push(true);
                        });
                        this.projects = projects;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Opens modal to edit projec data
             */
            editProject(project) {
                // Reset form fields
                this.formData.collaborativeNetwork = [];
                this.formData.customLink = [];
                this.formData.image = null;
                this.formData.deleteImage = 0;
                Object.keys(this.$language.available).forEach(la => {
                    this.formData.description[la] = '';
                });
                this.imageFile = null;
                this.project = project;

                // Image
                this.formData.image = this.getItemFieldValue(project, 'image', this.$language.current, false);

                // Description
                Object.keys(this.$language.available).forEach(l => {
                    let description = this.getItemFieldValue(project, 'description', l, false);
                    if (description !== null) this.formData.description[l] = description;
                });

                // Collaborative networks
                let collaborativeNetworks = this.getItemFieldValue(project, 'collaborativeNetwork', this.$language.current, true);
                if (collaborativeNetworks !== null) {
                    collaborativeNetworks.forEach(cn => {
                        let cnParts = cn.split('##');
                        this.formData.collaborativeNetwork.push({name: cnParts[1], link: cnParts[0]});
                    });
                }
                if (this.formData.collaborativeNetwork.length === 0) this.formData.collaborativeNetwork.push({ name: '', link: '' });

                // Custom links
                let customLinks = this.getItemFieldValue(project, 'customLink', this.$language.current, true);
                if (customLinks !== null) {
                    customLinks.forEach(cl => {
                        let clParts = cl.split('##');
                        this.formData.customLink.push({title: clParts[0], link: clParts[1]});
                    });
                }
                if (this.formData.customLink.length === 0) this.formData.customLink.push({ name: '', link: '' });

                // Show modal
                this.$bvModal.show('edit-project');
            },
            /**
             * Validates if a form field has a invalida value or not
             */
            validateState(name, language) {
                let dirty = false;
                let error = false;
                if (this.dataLoaded) {
                    dirty = (language === null ? this.$v.formData[name].$dirty : this.$v.formData[name][language].$dirty);
                    error =  (language === null ? this.$v.formData[name].$error : this.$v.formData[name][language].$error);
                }

                return dirty ? !error : null;
            },
            /**
             * Submits project data
             */
            submitProjectData() {
                this.$v.formData.$touch();
                // Check if form has errors
                if (!this.$v.formData.$anyError) this.updateItemData();
            },
            /**
             * Update item data
             */
            updateItemData() {
                // Modify item with new values
                let itemFields = this.project.secondaryFields;
                Object.keys(this.$language.available).forEach(lang => {
                    // Set image
                    // Delete image if checkbox is selected
                    if (this.formData.deleteImage == '1') delete itemFields[this.project.type + '_' + lang + '_image'];
                    else if (this.formData.image != '') itemFields[this.project.type + '_' + lang + '_image'] = [this.formData.image];
                    // Set description
                    itemFields[this.project.type + '_' + lang + '_description'] = [this.formData.description[lang].trim()];
                    // Set collaborative networks
                    let collaborativeNetworks = [];
                    this.formData.collaborativeNetwork.forEach(cn => {
                        if (cn.link !== '') collaborativeNetworks.push(cn.link + '##' + cn.name);
                    });
                    itemFields[this.project.type + '_' + lang + '_collaborativeNetwork'] = collaborativeNetworks;
                    // Set custom links
                    let customLinks = [];
                    this.formData.customLink.forEach(cl => {
                        if (cl.link !== '') {
                            if (cl.title != '') customLinks.push(cl.title + '##' + cl.link);
                            else customLinks.push(cl.link);
                        }
                    });
                    itemFields[this.project.type + '_' + lang + '_customLink'] = customLinks;
                });

                // Update item
                this.axios.put(this.apiURL + '/items/' + this.project.id, { fields: itemFields } )
                    .then(() => {
                        this.updateItemAdditionalData();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Updates item additional data
             */
            updateItemAdditionalData() {
                // Update item additional data
                let itemAdditionalData = {};

                // Set image
                let value = {};
                if (this.formData.deleteImage == '1') Object.keys(this.$language.available).forEach(lang => { value[lang] = null });
                else if (this.formData.image != '') Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.image });
                itemAdditionalData['image'] = [value];

                // Set description
                value = {};
                Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.description[lang].trim() });
                itemAdditionalData['description'] = [value];

                // Set collaborative networks
                let collaborativeNetworks = [];
                this.formData.collaborativeNetwork.forEach(cn => {
                    if (cn.link !== '') {
                        value = {};
                        Object.keys(this.$language.available).forEach(lang => { value[lang] = cn.link + '##' + cn.name });
                        collaborativeNetworks.push(value);
                    }
                });
                itemAdditionalData['collaborativeNetwork'] = collaborativeNetworks;

                // Set custom links
                let customLinks = [];
                this.formData.customLink.forEach(cl => {
                    if (cl.link !== '') {
                        value = {};
                        if (cl.title != '') Object.keys(this.$language.available).forEach(lang => { value[lang] = cl.title + '##' + cl.link });
                        else Object.keys(this.$language.available).forEach(lang => { value[lang] = cl.link });
                        customLinks.push(value);
                    }
                });
                itemAdditionalData['customLink'] = customLinks;

                // Update data
                this.axios.put(this.apiURL + '/itemsAdditionalData/' + this.project.id + '/updateFields', itemAdditionalData)
                    .then(() => {
                        this.$bvModal.hide('edit-project');
                        this.getProjects();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get all collaborative networks
             */
            getCollaborativeNetworks() {
                // Request related entities
                this.axios.get(this.apiURL + '/collaborativeNetworks')
                    .then(response => {
                        // Save data
                        this.collaborativeNetworks = response.data;
                    })
                    .catch(error => {
                        // Process error
                        this.processError(error);
                    });
            },
            /**
             * Adds a collaborative network
             */
            addCollaborativeNetwork() {
                this.formData.collaborativeNetwork.push({ name: '', link: '' });
            },
            /**
             * Delete a collaborative network
             */
            deleteCollaborativeNetwork(index) {
                this.formData.collaborativeNetwork.splice(index, 1);
            },
            /**
             * Adds a custom  link
             */
            addCustomLink() {
                this.formData.customLink.push({ title: '', link: '' });
            },
            /**
             * Delete a custom link
             */
            deleteCustomLink(index) {
                this.formData.customLink.splice(index, 1);
            },
            /**
             * Uploads a file to server
             */
            uploadFile() {
                let formData = new FormData();
                formData.append('file', this.imageFile, this.imageFile.name);

                // Send upload request
                this.axios.post(this.apiURL + '/uploadFile?prefix=items', formData)
                    .then(response => {
                        // Save image file name
                        this.formData.image = response.data.path.replace('/items/', '');
                        this.$refs['imageInput'].reset();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        }
    }
</script>
