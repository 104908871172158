<!--
--------------------------------------------------------------------------
   PersonalCornerUserSearches.vue
--------------------------------------------------------------------------

    Component to build user searches

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner-user-searches" class="container">
        <div class="row">
            <div class="col">
                <h3 class="mt-3"><translate>Saved searches</translate></h3>
                <b-alert variant="info" :show="searches.length === 0">
                    <translate>No saved searchs found.</translate>
                </b-alert>
                <b-table :fields="searchFields" :items="searches" v-if="searches.length > 0">
                    <template #cell(data)="data">
                        {{ data.item.title }}<br />
                        {{ data.item.numResults }}<br />
                        {{ data.item.lastCheck }}<br />
                        <div class="text-center mt-2">
                            <a class="btn btn-light btn-sm mr-2" :href="data.item.url" target="_blank" :title="'View'|translate"><i class="fas fa-search"></i></a>
                            <b-button size="sm" variant="primary" @click="editSearch(data.item)" class="mr-2" :title="'Edit'|translate"><i class="fas fa-edit"></i></b-button>
                            <b-button size="sm" variant="info" @click="checkSearch(data.item)" class="mr-2" :title="'Check search'|translate"><i class="fas fa-redo-alt"></i></b-button>
                            <b-button size="sm" variant="warning" @click="exportData($store.state.config.URL + '/' + data.item.url, 'RSS')" class="mr-2" :title="'RSS'|translate"><i class="fas fa-rss"></i></b-button>
                            <b-button size="sm" :variant="data.item.alert ? 'success' : 'danger'" @click="changeNotificationsStatus(data.item)" class="mr-2" :title="'Change notifications status'|translate"><i class="fas fa-envelope"></i></b-button>
                            <b-button size="sm" variant="danger" @click="confirmDeleteSearch(data.item)" :title="'Delete'|translate"><i class="fas fa-trash-alt"></i></b-button>
                        </div>
                    </template>
                    <template #cell(title)="data">
                        {{ data.item.title }}
                    </template>
                    <template #cell(numResults)="data">
                        {{ data.item.numResults }}
                    </template>
                    <template #cell(lastCheck)="data">
                        {{ data.item.lastCheck }}
                    </template>
                    <template #cell(buttons)="data">
                        <div class="text-right">
                            <a class="btn btn-light btn-sm mr-2" :href="data.item.url" target="_blank" :title="'View'|translate"><i class="fas fa-search"></i></a>
                            <b-button size="sm" variant="primary" @click="editSearch(data.item)" class="mr-2" :title="'Edit'|translate"><i class="fas fa-edit"></i></b-button>
                            <b-button size="sm" variant="info" @click="checkSearch(data.item)" class="mr-2" :title="'Check search'|translate"><i class="fas fa-redo-alt"></i></b-button>
                            <b-button size="sm" variant="warning" @click="exportData($store.state.config.URL + '/' + data.item.url, 'RSS')" class="mr-2" :title="'RSS'|translate"><i class="fas fa-rss"></i></b-button>
                            <b-button size="sm" :variant="data.item.alert ? 'success' : 'danger'" @click="changeNotificationsStatus(data.item)" class="mr-2" :title="'Change notifications status'|translate"><i class="fas fa-envelope"></i></b-button>
                            <b-button size="sm" variant="danger" @click="confirmDeleteSearch(data.item)" :title="'Delete'|translate"><i class="fas fa-trash-alt"></i></b-button>
                        </div>
                    </template>
                </b-table>

                <b-modal id="edit-search" :title="'Edit search'|translate">
                    <b-form @submit.stop.prevent="saveSearch" novalidate>
                        <b-form-group id="group-title" :label="'Title'|translate" label-for="title">
                            <b-form-input id="title" name="title" v-model="$v.userSearchData.title.$model" type="text" :placeholder="'Title'|translate" :state="validateState('title')"></b-form-input>
                            <b-form-invalid-feedback id="title"><translate>Title is required.</translate></b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-checkbox id="alert" v-model="$v.userSearchData.alert.$model" name="alert" :value="true" :unchecked-value="false">
                            <translate>Receive periodic notifications of new results via email</translate>
                        </b-form-checkbox>
                        <br />
                    </b-form>
                    <template #modal-footer="{ cancel }">
                        <b-button type="submit" variant="primary" @click="saveSearch"><translate>Save</translate></b-button>
                        <b-button variant="secondary" @click="cancel()"><translate>Close</translate></b-button>
                    </template>
                </b-modal>

                <b-modal id="confirm-delete-search" :title="'Delete search'|translate">
                    <translate>Dou you really want to delete this search?</translate>
                    <template #modal-footer="{ cancel }">
                        <b-button type="danger" variant="primary" @click="deleteSearch"><translate>Delete</translate></b-button>
                        <b-button variant="secondary" @click="cancel()"><translate>Close</translate></b-button>
                    </template>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: 'personal-corner-user-searches',
        mixins: [validationMixin],
        data() {
            return {
                searchFields: [
                    { key: 'title', label: this.$gettext('Name'), thStyle: { width: '40%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'numResults', label: this.$gettext('Num. results'), thStyle: { width: '15%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'lastCheck', label: this.$gettext('Last check'), thStyle: { width: '20%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'data', label: this.$gettext('Data'), thStyle: { width: '100%'}, tdClass: 'd-table-cell d-sm-none', thClass: 'd-table-cell d-sm-none' },
                    { key: 'buttons', label: '', thStyle: { width: '25%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                ],
                searches: [],
                userSearchData: {
                    id: '',
                    title: '',
                    url: '',
                    alert: false,
                    numResults: 0,
                    lastCheck: ''
                },
                searchToDelete: null
            }
        },
        validations: {
            userSearchData: {
                title: { required },
                alert: {}
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Get user searches
             */
            init() {
                this.getUserSearches();
            },
            /**
             * Get searches
             */
            getUserSearches() {
                // Get user searches
                this.axios.get(this.apiURL + '/users/' + this.$store.state.user.username + '/searches')
                    .then(response => {
                        this.searches = response.data;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Opens edit dialog
             */
            editSearch(userSearch) {
                // Set formData
                this.userSearchData.id = userSearch.id;
                this.userSearchData.title = userSearch.title;
                this.userSearchData.url = userSearch.url;
                this.userSearchData.alert = userSearch.alert;
                this.userSearchData.numResults = userSearch.numResults;
                this.userSearchData.lastCheck = userSearch.lastCheck;
                // Show modal to edit search
                this.$bvModal.show('edit-search');
            },
            /**
             * Update search
             */
            saveSearch() {
                this.$v.userSearchData.$touch();
                // Check if form has errors
                if (!this.$v.userSearchData.$anyError) {
                    // Save data
                    this.axios.put(this.apiURL + '/users/' + this.$store.state.user.username + '/searches/' + this.userSearchData.id, this.cleanFormData(this.userSearchData))
                        .then(() => {
                            this.$bvModal.hide('edit-search');
                            let index = this.searches.findIndex(s => s.id === this.userSearchData.id);
                            if (index !== -1) this.$set(this.searches, index, this.userSearchData);
                            this.showMessage(this.$gettext('Updated successfully'), this.$gettext('Your search has been updated successfully.'), 'success', false);
                        })
                        .catch(error => {
                            this.processError(error);
                        });
                }
            },
             /**
             * Checks num results for the search
             */
            checkSearch(userSearch) {
                 this.axios.get(this.apiURL + '/users/' + this.$store.state.user.username + '/searches/' + userSearch.id + '/check')
                     .then(() => {
                         this.showMessage(this.$gettext('Checked successfully'), this.$gettext('Your search has been checked successfully.'), 'success', false);
                     })
                     .catch(error => {
                         this.processError(error);
                     });
            },
            /**
             * Modify notifications status
             */
            changeNotificationsStatus(userSearch) {
                // Modify alert and save changes
                userSearch.alert = !userSearch.alert;
                this.axios.put(this.apiURL + '/users/' + this.$store.state.user.username + '/searches/' + userSearch.id, this.cleanFormData(userSearch))
                    .then(() => {
                        this.showMessage(this.$gettext('Updated successfully'), this.$gettext('Your search has been updated successfully.'), 'success', false);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Show the dialog to confirm delete a search
             */
            confirmDeleteSearch(userSearch) {
                this.searchToDelete = userSearch;
                this.$bvModal.show('confirm-delete-search');
            },
            /**
             * Delete a user search
             */
            deleteSearch() {
                // Delete search
                this.axios.delete(this.apiURL + '/users/' + this.$store.state.user.username + '/searches/' + this.searchToDelete.id)
                    .then(() => {
                        this.$bvModal.hide('confirm-delete-search');
                        let index = this.searches.findIndex(s => s.id === this.searchToDelete.id);
                        if (index !== -1) this.searches.splice(index, 1);
                        this.showMessage(this.$gettext('Deleted successfully'), this.$gettext('Your search has been deleted successfully.'), 'success', false);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Validates if a form fierld has a invalida value or not
             */
            validateState(name) {
                const { $dirty, $error } = this.$v.userSearchData[name];
                return $dirty ? !$error : null;
            }
        }
    }
</script>
