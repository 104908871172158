<!--
--------------------------------------------------------------------------
   PersonalCornerUserSimulations.vue
--------------------------------------------------------------------------

    Component to magae user simulations

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner-user-simulations" class="container">
        <div class="row">
            <div class="col">
                <h3 v-translate>Create a simulation</h3>
                <p>
                    <translate>This form allows you to create a simulation of a research group or entity from the aggregation of the scientific production of the components that are added to it. Fill in the form to create your simulation:</translate>
                </p>
                <b-form @submit.stop.prevent="submitUserSimulation" novalidate>
                    <b-form-group id="group-title" :label="'Title'|translate" label-for="title">
                        <b-form-input id="title" name="title" v-model="$v.formData.title.$model" type="text" :placeholder="'Title'|translate" :state="validateState('title')"></b-form-input>
                        <b-form-invalid-feedback id="title"><translate>Title is required.</translate></b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="group-components" :label="'Components'|translate" label-for="components">
                        <b-input-group :label="'Components'|translate" label-for="components" :class="{ 'is-invalid': formSubmitted && formData.components.length == 0 }">
                            <template #prepend>
                                <b-form-select id="componentSearchType" name="componentSearchType" v-model="componentSearchType" :options="componentSearchTypeOptions"></b-form-select>
                            </template>
                            <vue-bootstrap-typeahead
                                :data="componentSearchSuggestions"
                                v-model="componentSearchText"
                                :serializer="s => getItemFieldValue(s, 'title', $language.current, false)"
                                :placeholder="$gettext('Search researcher or organization name')"
                                @hit="selectSuggestion($event)"
                                :minMatchingChars="4"
                                ref="componentsSuggestions"
                                class="componentsSuggestions"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback id="components"><translate>One component is required.</translate></b-form-invalid-feedback>
                        <b-button pill variant="warning" size="sm" v-for="(component, index) in formData.components" :key="'component.' + index" class="mt-2 mr-2 text-white" @click="deleteComponent(index)">{{ component.title }} <i class="fas fa-times"></i></b-button>
                    </b-form-group>
                    <br />
                    <b-button type="submit" variant="primary"><translate>Save</translate></b-button>
                </b-form>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h3 class="mt-5"><translate>Your simulations</translate></h3>
                <b-alert variant="info" :show="simulations.length === 0">
                    <translate>No simulations found.</translate>
                </b-alert>
                <b-table :fields="simulationFields" :items="simulations" v-if="simulations.length > 0">
                    <template #cell(data)="data">
                        {{ data.item.title }}<br />
                        <a :href="$store.state.config.URL + '/' + $gettext('simulations') + '/' + data.item.url" target="_blank">{{ $store.state.config.URL}}/{{ $gettext('simulations') }}/{{ data.item.url }}</a><br />
                        {{ data.item.creationDate }}<br />
                        <div class="text-center mt-2">
                            <a class="btn btn-light btn-sm mr-2" :href="$store.state.config.URL + '/' + $gettext('simulations') + '/' + data.item.url" target="_blank" :title="'View'|translate"><i class="fas fa-search"></i></a>
                            <b-button size="sm" variant="primary" @click="editSimulation(data.item)" class="mr-2" :title="'Edit'|translate"><i class="fas fa-edit"></i></b-button>
                            <b-button size="sm" variant="danger" @click="confirmDeleteSimulation(data.item)" :title="'Delete'|translate"><i class="fas fa-trash-alt"></i></b-button>
                        </div>
                    </template>
                    <template #cell(title)="data">
                        {{ data.item.title }}
                    </template>
                    <template #cell(url)="data">
                        <a :href="$store.state.config.URL + '/' + $gettext('simulations') + '/' + data.item.url" target="_blank">{{ $store.state.config.URL}}/{{ $gettext('simulations') }}/{{ data.item.url }}</a>
                    </template>
                    <template #cell(components)="data">
                        <p v-for="(component, index) in data.item.components" :key="'simulation.' + data.id + '.' + index" style="margin-bottom: 0px;">{{ component.title }} ({{ $gettext(component.type) }})</p>
                    </template>
                    <template #cell(creationDate)="data">
                        {{ data.item.creationDate }}
                    </template>
                    <template #cell(buttons)="data">
                        <div class="text-right">
                            <a class="btn btn-light btn-sm mr-2" :href="$store.state.config.URL + '/' + $gettext('simulations') + '/' + data.item.url" target="_blank" :title="'View'|translate"><i class="fas fa-search"></i></a>
                            <b-button size="sm" variant="primary" @click="editSimulation(data.item)" class="mr-2" :title="'Edit'|translate"><i class="fas fa-edit"></i></b-button>
                            <b-button size="sm" variant="danger" @click="confirmDeleteSimulation(data.item)" :title="'Delete'|translate"><i class="fas fa-trash-alt"></i></b-button>
                        </div>
                    </template>
                </b-table>

                <b-modal id="edit-simulation" size="lg" :title="'Edit simulation'|translate">
                    <b-form @submit.stop.prevent="saveSimulation" novalidate>
                        <b-form-group id="group-title" :label="'Title'|translate" label-for="title">
                            <b-form-input id="title" name="title" v-model="$v.userSimulationData.title.$model" type="text" :placeholder="'Title'|translate" :state="validateState('title')"></b-form-input>
                            <b-form-invalid-feedback id="title"><translate>Title is required.</translate></b-form-invalid-feedback>
                        </b-form-group>

                        <br />
                    </b-form>

                    <b-form-group id="group-components" :label="'Components'|translate" label-for="components">
                        <b-input-group :label="'Components'|translate" label-for="components" :class="{ 'is-invalid': editFormSubmitted && userSimulationData.components.length == 0 }">
                            <template #prepend>
                                <b-form-select id="ediComponentSearchType" name="ediComponentSearchType" v-model="editComponentSearchType" :options="componentSearchTypeOptions"></b-form-select>
                            </template>
                            <vue-bootstrap-typeahead
                                :data="editComponentSearchSuggestions"
                                v-model="editComponentSearchText"
                                :serializer="s => getItemFieldValue(s, 'title', $language.current, false)"
                                :placeholder="$gettext('Search researcher or organization name')"
                                @hit="editSelectSuggestion($event)"
                                :minMatchingChars="4"
                                ref="editComponentsSuggestions"
                                class="componentsSuggestions"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback id="components"><translate>One component is required.</translate></b-form-invalid-feedback>
                        <b-button pill variant="warning" size="sm" v-for="(component, index) in userSimulationData.components" :key="'editComponent.' + index" class="mt-2 mr-2 text-white" @click="editDeleteComponent(index)">{{ component.title }} <i class="fas fa-times"></i></b-button>
                    </b-form-group>

                    <template #modal-footer="{ cancel }">
                        <b-button type="submit" variant="primary" @click="saveSimulation"><translate>Save</translate></b-button>
                        <b-button variant="secondary" @click="cancel()"><translate>Close</translate></b-button>
                    </template>
                </b-modal>

                <b-modal id="confirm-delete-simulation" :title="'Delete simulation'|translate">
                    <translate>Dou you really want to delete this simulation?</translate>
                    <template #modal-footer="{ cancel }">
                        <b-button type="danger" variant="primary" @click="deleteSimulation"><translate>Delete</translate></b-button>
                        <b-button variant="secondary" @click="cancel()"><translate>Close</translate></b-button>
                    </template>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';
    import { required } from 'vuelidate/lib/validators';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import _ from 'underscore';
    import moment from 'moment';

    export default {
        name: 'personal-corner-user-simulations',
        mixins: [validationMixin],
        components: {
            VueBootstrapTypeahead
        },
        data() {
            return {
                formData: {
                    title: '',
                    components: [],
                },
                userSimulationData: {
                    id: '',
                    title: '',
                    url: '',
                    creationDate: '',
                    components: [],
                },
                formSubmitted: false,
                editFormSubmitted: false,
                componentSearchText: '',
                componentSearchSuggestions: [],
                componentSearchType: 'person',
                componentSearchTypeOptions: [
                    { value: 'person', text: this.$gettext('Researcher') },
                    { value: 'researchGroup', text: this.$gettext('Research group') },
                    { value: 'department', text: this.$gettext('Department') },
                    { value: 'teachingCenter', text: this.$gettext('Teaching center') },
                    { value: 'institute', text: this.$gettext('Institute') },
                    { value: 'relatedEntity', text: this.$gettext('Related entity') },
                    { value: 'campus', text: this.$gettext('Campus') }
                ],
                editComponentSearchText: '',
                editComponentSearchSuggestions: [],
                editComponentSearchType: 'person',
                simulationFields: [
                    { key: 'title', label: this.$gettext('Title'), thStyle: { width: '20%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'url', label: this.$gettext('URL'), thStyle: { width: '25%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'components', label: this.$gettext('Components'), thStyle: { width: '25%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'creationDate', label: this.$gettext('Created at'), thStyle: { width: '15%'}, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'buttons', label: '', thStyle: { width: '15%' }, tdClass: 'd-none d-sm-table-cell', thClass: 'd-none d-sm-table-cell' },
                    { key: 'data', label: this.$gettext('Data'), thStyle: { width: '100%'}, tdClass: 'd-table-cell d-sm-none', thClass: 'd-table-cell d-sm-none' }
                ],
                simulations: [],
                simulationToDelete: null
            }
        },
        validations: {
            formData: {
                title: { required }
            },
            userSimulationData: {
                title: { required }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Get user simulations
             */
            init() {
                this.getUserSimulations();
            },
            /**
             * Get simulations
             */
            getUserSimulations() {
                // Get user simulations
                this.axios.get(this.apiURL + '/users/' + this.$store.state.user.username + '/simulations')
                    .then(response => {
                        this.simulations = response.data;
                        this.simulations.forEach(s => s.components = JSON.parse(s.components) );
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Validates if a form field has an invalid value or not
             */
            validateState(name) {
                const { $dirty, $error } = this.$v.formData[name];
                return $dirty ? !$error : null;
            },
            /**
             * Submits user simulation
             */
            submitUserSimulation() {
                this.$v.formData.$touch();
                // Check if form has errors
                this.formSubmitted = true;
                if (!this.$v.formData.$anyError && this.formData.components.length > 0) {
                    // Send data to save
                    let formData = { title: this.formData.title, components: JSON.stringify(this.formData.components), url: Math.floor(Math.random() * 100) + '-' + this.friendlyURL(this.formData.title), creationDate:  moment().format('YYYY-MM-DD')};
                    this.axios.post(this.apiURL + '/users/' + this.$store.state.user.username + '/simulations', this.cleanFormData(formData))
                        .then(() => {
                            // Get simulations
                            this.getUserSimulations();

                            // Reset form
                            this.formData.title = '';
                            this.formData.components = [];
                            this.$refs.componentsSuggestions.inputValue = '';

                            // Notify user
                            this.showMessage(this.$gettext('Saved successfully'), this.$gettext('Your simulation has been saved successfully.'), 'success', false);
                        })
                        .catch(error => {
                            this.processError(error);
                        });
                }
            },
            /**
             * Search items by name and type
             */
            async getItemsByNameType(query, edit) {
                // Search items suggestions
                let type = (this.componentSearchType === 'person' ? 'person' : 'organization');
                query = type + '_' + this.$language.current + '_title:(' + this.filterQuery(query) + '* OR ' + this.filterQuery(query.toUpperCase()) + '* OR ' + this.filterQuery(query.toLowerCase().replace(/\b[a-z]/g, function(letra) { return letra.toUpperCase(); })) + '*)';
                let sort = type + '_' + this.$language.current + '_title_sort asc';
                let formData =  { query: query, facets: [], sort: sort, page: { pageIdx: 0, pageSize: this.$store.state.config.defaultPageSize * 1 } };
                this.axios.post(this.apiURL + '/items/search', formData)
                    .then(response => {
                        if (edit) this.editComponentSearchSuggestions = response.data.content;
                        else this.componentSearchSuggestions = response.data.content;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Selects a item suggestion
             */
            selectSuggestion(item) {
                if (!this.formData.components.find(c => c.title === this.getItemFieldValue(item, 'title', this.$language.current, false))) this.formData.components.push({ title: this.getItemFieldValue(item, 'title', this.$language.current, false), type: this.componentSearchType });
                this.$refs.componentsSuggestions.inputValue = '';
            },
            /**
             * Deletes a component from the list
             */
            deleteComponent(index) {
                this.formData.components.splice(index,1);
            },
            /**
             * Show the dialog to confirm delete a simulation
             */
            confirmDeleteSimulation(userSimulation) {
                this.simulationToDelete = userSimulation;
                this.$bvModal.show('confirm-delete-simulation');
            },
            /**
             * Delete a user simulation
             */
            deleteSimulation() {
                // Delete simulation
                this.axios.delete(this.apiURL + '/users/' + this.$store.state.user.username + '/simulations/' + this.simulationToDelete.id)
                    .then(() => {
                        // Hide modal and update array
                        this.$bvModal.hide('confirm-delete-simulation');
                        let index = this.simulations.findIndex(s => s.id === this.simulationToDelete.id);
                        if (index !== -1) this.simulations.splice(index, 1);

                        // Notify user
                        this.showMessage(this.$gettext('Deleted successfully'), this.$gettext('Your simulation has been deleted successfully.'), 'success', false);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Opens edit dialog
             */
            editSimulation(userSimulation) {
                // Set form data
                this.userSimulationData.id = userSimulation.id;
                this.userSimulationData.title = userSimulation.title;
                this.userSimulationData.url = userSimulation.url;
                this.userSimulationData.components =  [...userSimulation.components];
                this.userSimulationData.creationDate = userSimulation.creationDate;
                // Show modal to edit simulation
                this.$bvModal.show('edit-simulation');
            },
            /**
             * Selects a item suggestion in edit form
             */
            editSelectSuggestion(item) {
                // Check if already exists item in the list
                if (!this.userSimulationData.components.find(c => c.title === this.getItemFieldValue(item, 'title', this.$language.current, false))) this.userSimulationData.components.push({ title: this.getItemFieldValue(item, 'title', this.$language.current, false), type: this.editComponentSearchType });
                this.$refs.editComponentsSuggestions.inputValue = '';
            },
            /**
             * Deletes a component from the list in edit form
             */
            editDeleteComponent(index) {
                this.userSimulationData.components.splice(index,1);
            },
            /**
             * Saves user simulation
             */
            saveSimulation() {
                this.$v.userSimulationData.$touch();
                // Check if form has errors
                this.editFormSubmitted = true;
                if (!this.$v.userSimulationData.$anyError && this.userSimulationData.components.length > 0) {
                    // Update simulation data
                    let formData = {
                        id: this.userSimulationData.id,
                        title: this.userSimulationData.title,
                        components: JSON.stringify(this.userSimulationData.components),
                        url: this.userSimulationData.url,
                        creationDate: this.userSimulationData.creationDate
                    };
                    this.axios.put(this.apiURL + '/users/' + this.$store.state.user.username + '/simulations/' + this.userSimulationData.id, this.cleanFormData(formData))
                        .then(() => {
                            // Get simulations
                            this.getUserSimulations();

                            // Reset component search and hide modal
                            this.$refs.editComponentsSuggestions.inputValue = '';
                            this.$bvModal.hide('edit-simulation');

                            // Notify user
                            this.showMessage(this.$gettext('Updated successfully'), this.$gettext('Your simulation has been updated successfully.'), 'success', false);
                        })
                        .catch(error => {
                            this.processError(error);
                        });
                }
            },
        },
        watch: {
            componentSearchText: _.debounce(function(query) { this.getItemsByNameType(query, false) }, 500),
            editComponentSearchText: _.debounce(function(query) { this.getItemsByNameType(query, true) }, 500)
        }
    }
</script>
