<!--
--------------------------------------------------------------------------
   PersonalCornerUserData.vue
--------------------------------------------------------------------------

    Component to build user data form

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner-user-data" class="container">
        <div class="row">
            <div class="col">
                <h3 v-translate>Your data</h3>
                <b-form @submit.stop.prevent="submitUserData" novalidate>
                    <b-form-group id="group-name" :label="'Name'|translate" label-for="name">
                        <b-form-input id="name" name="name" v-model="$v.formData.name.$model" type="text" :placeholder="'Name'|translate" :state="validateState('name')"></b-form-input>
                        <b-form-invalid-feedback id="name"><translate>Name is required.</translate></b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="group-surname" :label="'Surname'|translate" label-for="surname">
                        <b-form-input id="surname" v-model="$v.formData.surname.$model" type="text" :placeholder="'Surname'|translate" :state="validateState('surname')"></b-form-input>
                        <b-form-invalid-feedback id="surname"><translate>Surname is required.</translate></b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="group-email" :label="'E-mail'|translate" label-for="email" :description="'Email address to send you notifications about your saved searches'|translate">
                        <b-form-input id="email" v-model="$v.formData.email.$model" type="email" :placeholder="'E-mail'|translate" :state="validateState('email')"></b-form-input>
                        <b-form-invalid-feedback id="email"><translate>E-mail is required or invalid format.</translate></b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="group-language" :label="'Language'|translate" label-for="language" :description="'Language for e-mail notifications'|translate">
                        <b-form-select v-model="$v.formData.language.$model" :state="validateState('language')">
                            <b-form-select-option :value="''"><translate>Select a language</translate></b-form-select-option>
                            <b-form-select-option :value="key" v-for="(name, key) in $language.available" :key="key">{{ name }}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="language"><translate>Language is required.</translate></b-form-invalid-feedback>
                    </b-form-group>
                    <br />
                    <b-button type="submit" variant="primary"><translate>Save</translate></b-button>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';
    import { required, email } from 'vuelidate/lib/validators';

    export default {
        name: 'personal-corner-user-data',
        mixins: [validationMixin],
        data() {
            return {
                formData: {
                    username: this.$store.state.user.username,
                    name: this.$store.state.user.name,
                    surname: this.$store.state.user.surname,
                    email: this.$store.state.user.email,
                    language: this.$store.state.user.language,
                    role: this.$store.state.user.role,
                    canImpersonate: this.$store.state.user.canImpersonate
                },
                searchFields: [
                    { key: 'title', label: this.$gettext('Name'), thStyle: { width: '40%'} },
                    { key: 'numResults', label: this.$gettext('Num. results'), thStyle: { width: '15%'} },
                    { key: 'lastCheck', label: this.$gettext('Last check'), thStyle: { width: '20%'} },
                    { key: 'buttons', label: '', thStyle: { width: '25%' } }
                ],
                searchs: []
            }
        },
        validations: {
            formData: {
                name: { required },
                surname: { required },
                email: { required, email },
                language: { required }
            }
        },
        methods: {
            /**
             * Validates if a form fierld has a invalida value or not
             */
            validateState(name) {
                const { $dirty, $error } = this.$v.formData[name];
                return $dirty ? !$error : null;
            },
            /**
             * Submits user data
             */
            submitUserData() {
                this.$v.formData.$touch();
                // Check if form has errors
                if (!this.$v.formData.$anyError) {
                    // Send data to save
                    this.axios.put(this.apiURL + '/users/' + this.$store.state.user.username, this.cleanFormData(this.$v.formData.$model))
                        .then(response => {
                            this.$root.$emit('setUserData', response.data);
                            this.showMessage(this.$gettext('Saved successfully'), this.$gettext('Your information has been saved successfully.'), 'success', false);
                        })
                        .catch(error => {
                            this.processError(error);
                        });
                }
            },

        }
    }
</script>
