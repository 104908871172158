<!--
--------------------------------------------------------------------------
   PersonalCornerProfile.vue
--------------------------------------------------------------------------

    Component to edit profile data

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner-profile-data" class="container">
        <div class="row">
            <div class="col">
                <p v-translate>Fill in the form below to complete the information of your page:</p>
                <b-form @submit.stop.prevent="submitProfileData" novalidate class="mt-4" v-if="dataLoaded">

                    <b-form-group id="group-image-file" :label="'Image'|translate" label-for="imageFile" :class="{ 'mb-1' : formData.image != null, 'mb-3' : formData.image == null}" :description="$gettext('Select an image file from your hard drive to include on your page, or check the box to delete the current image. The file must be in JPG or PNG format, and the size should be 120 x 120 pixels.')">
                        <b-form-file v-model="imageFile" :placeholder="$gettext('Choose an image...')" @input="uploadFile" ref="imageInput"></b-form-file>
                    </b-form-group>

                    <b-form-checkbox v-model="formData.deleteImage" value="1" unchecked-value="0" class="mb-3" v-if="formData.image != null">
                        <translate>Delete current image</translate> (<a :href="$store.state.config.URL + '/images/items/' + formData.image" target="_blank">{{ formData.image }}</a>)
                    </b-form-checkbox>

                    <b-form-group id="group-show-featured" :label="'Show featured scientific production filter?'|translate" label-for="expertsGuide" class="mb-3" :description="$gettext('Choose if you want the featured production filter to be displayed on your portal profile. For more information about your featured production check FAQ section.')">
                        <b-form-select v-model="$v.formData.showFeatured.$model">
                            <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                            <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="group-default-featured" :label="'Enable featured scientific production filter by default?'|translate" label-for="defaultFeatured" class="mb-3" :description="$gettext('Choose if you want the featured production filter enabled by default when users view your profile.')">
                        <b-form-select v-model="$v.formData.defaultFeatured.$model">
                            <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                            <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="group-hindex" :label="'H index'|translate" label-for="hindex" class="mb-3" :description="available.hIndex ? $gettext('Select to indicate whether or not you want your h-index, obtained from the DRAC database, to be shown on your page.') : ''">
                        <b-alert variant="info" show v-html="notAvailableMessage" v-if="!available.hIndex"></b-alert>
                        <b-form-select v-model="$v.formData.showHindex.$model" v-else>
                            <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                            <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="group-expertise" :label="$gettext('Areas of expertise') + ' (' + name + ')'" :label-for="'expertise.' + key" :key="'expertise.' + key" class="mb-3" v-for="(name, key) in $language.available" :description="$gettext('Enter key topics that define your scientific and technical area of expertise. The topics must be separated by commas and should be entered in all of the languages indicated on the form.')">
                        <b-form-input :id="'expertise.' + key" :name="'expertise.' + key" v-model="$v.formData.expertise[key].$model" type="text" :placeholder="$gettext('keyword 1, keyword2, etc...')" :state="validateState('expertise', key)"></b-form-input>
                        <b-form-invalid-feedback :id="'expertise.' + key"><translate>Areas of expertise are required in all languages.</translate></b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="group-professional-category" :label="'Professional category'|translate" label-for="professionalCategory" class="mb-3" :description="available.professionalCategory ? $gettext('Select to indicate whether or not you want data concerning your occupational category, obtained from the DRAC database, to be shown on your page.') : ''">
                        <b-alert variant="info" show v-html="notAvailableMessage" v-if="!available.professionalCategory"></b-alert>
                        <div v-else>
                            <ul>
                                <template v-for="(name, key) in $language.available">
                                    <li v-for="(category, key2) in getItemFieldValue(item, 'professionalCategory', key, true)" :key="'professionalCategory.' + key + '.' + key2">
                                      {{ category }} ({{ name }})
                                    </li>
                                </template>
                            </ul>
                            <b-form-select v-model="$v.formData.showProfessionalCategoy.$model">
                                <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                                <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                            </b-form-select>
                        </div>
                    </b-form-group>

                    <b-form-group id="group-doctorate" :label="'Doctoral courses'|translate" label-for="doctorate" class="mb-3" :description="available.doctorate ? $gettext('Select to indicate whether or not you want data concerning your doctoral degrees, obtained from the DRAC database, to be shown on your page.') : ''">
                        <b-alert variant="info" show v-html="notAvailableMessage" v-if="!available.doctorate"></b-alert>
                        <div v-else>
                            <ul>
                                <template v-for="(name, key) in $language.available">
                                    <li v-for="(doctorate, key2) in getItemFieldValue(item, 'doctorate', key, true)" :key="'doctorate.' + key + '.' + key2">
                                      {{ doctorate }} ({{ name }})
                                    </li>
                                </template>
                            </ul>
                            <b-form-select v-model="$v.formData.showDoctorate.$model">
                                <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                                <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                            </b-form-select>
                        </div>
                    </b-form-group>

                    <b-form-group id="group-university-degree" :label="'University degree'|translate" label-for="universityDegree" class="mb-3" :description="available.universityDegree ? $gettext('Select to indicate whether or not you want data concerning your university qualifications, obtained from the DRAC database, to be shown on your page.') : ''">
                        <b-alert variant="info" show v-html="notAvailableMessage" v-if="!available.universityDegree"></b-alert>
                        <div v-else>
                            <ul>
                                <template v-for="(name, key) in $language.available">
                                  <li v-for="(degree, key2) in getItemFieldValue(item, 'universityDegree', key, true)" :key="'universityDegree.' + key + '.' + key2">
                                    {{ degree }} ({{ name }})
                                  </li>
                                </template>
                            </ul>
                            <b-form-select v-model="$v.formData.showUniversityDegree.$model">
                                <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                                <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                            </b-form-select>
                        </div>
                    </b-form-group>

                    <b-form-group id="group-postgraduate-degree" :label="'Postgraduated qualification'|translate" label-for="postgraduateDegree" class="mb-3" :description="available.postgraduateDegree ? $gettext('Select to indicate whether or not you want data concerning your postgraduate qualifications, obtained from the DRAC database, to be shown on your page.') : ''">
                        <b-alert variant="info" show v-html="notAvailableMessage" v-if="!available.postgraduateDegree"></b-alert>
                        <div v-else>
                            <ul>
                                <template v-for="(name, key) in $language.available">
                                  <li v-for="(degree, key2) in getItemFieldValue(item, 'postgraduateDegree', key, true)" :key="'postgraduateDegree.' + key + '.' + key2">
                                    {{ degree }} ({{ name }})
                                  </li>
                                </template>
                            </ul>
                            <b-form-select v-model="$v.formData.showPostgraduateDegree.$model">
                                <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                                <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                            </b-form-select>
                        </div>
                    </b-form-group>

                    <b-form-group id="group-related-entity-member" :label="'Related entities'|translate" label-for="relatedEntityMember" class="mb-3" :description="$gettext('Select the research-related entities to which you belong.')">
                        <b-input-group v-for="(v, index) in $v.formData.relatedEntityMember.$each.$iter" :key="'relatedEntityMember.' + index" class="mb-2">
                            <b-form-select v-model="v.id.$model">
                                <b-form-select-option value="">{{ $gettext('-- Select a related entity --')}}</b-form-select-option>
                                <b-form-select-option :value="relatedEntity.id" v-for="(relatedEntity, index2) in relatedEntities" :key="'relatedEntity.' + index + '.' + index2">{{ relatedEntity.name }}</b-form-select-option>
                            </b-form-select>
                            <b-input-group-append>
                                <b-button variant="success" @click="addRelatedEntity()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteRelatedEntity(index)" :disabled="formData.relatedEntityMember.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group id="group-collaborative-network" :label="'Collaborative networks'|translate" label-for="collaborativeNetwork" class="mb-3" :description="$gettext('Enter links for the collaborative networks to which you belong. Select the network from the drop-down list and enter the link to your profile or page on the selected network in the text box.')">
                        <b-input-group v-for="(v, index) in $v.formData.collaborativeNetwork.$each.$iter" :key="'collaborativeNetwork.' + index" class="mb-2">
                            <b-input-group-prepend>
                                <b-form-select v-model="v.name.$model" style="width: 200px;">
                                    <b-form-select-option value="">{{ $gettext('-- Select a network --')}}</b-form-select-option>
                                    <b-form-select-option :value="collaborativeNetwork.name" v-for="(collaborativeNetwork, index2) in collaborativeNetworks" :key="'collaborativeNetwork.' + index + '.' + index2">{{ collaborativeNetwork.name }}</b-form-select-option>
                                </b-form-select>
                            </b-input-group-prepend>
                            <b-form-input v-model="v.link.$model" placeholder="http://www.example.com/user"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="success" @click="addCollaborativeNetwork()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteCollaborativeNetwork(index)" :disabled="formData.collaborativeNetwork.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group id="group-custom-link" :label="'Links of interest'|translate" label-for="customLink" class="mb-3" :description="$gettext('Enter the links of interest that you would like to include on your page.')">
                        <b-input-group v-for="(v, index) in $v.formData.customLink.$each.$iter" :key="'customLink.' + index" class="mb-2">
                            <b-input-group-prepend>
                                <b-form-input v-model="v.title.$model" :placeholder="$gettext('Title (optional)')"></b-form-input>
                            </b-input-group-prepend>
                            <b-form-input v-model="v.link.$model" placeholder="http://www.example.com"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="success" @click="addCustomLink()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteCustomLink(index)" :disabled="formData.customLink.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <h3>{{ $gettext('UPC experts directory for the media') }}</h3>

                    <b-form-group id="group-experts-guide" :label="'Appear in the UPC experts directory for the media?'|translate" label-for="expertsGuide" class="mb-3" :description="$gettext('Choose if you want to appear or not in the UPC experts directory for the media.')">
                        <b-form-select v-model="$v.formData.expertsGuide.$model">
                            <b-form-select-option value="1"><translate>Yes</translate></b-form-select-option>
                            <b-form-select-option value="0"><translate>No</translate></b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="group-telephone" :label="'Contact telephones'|translate" label-for="telephone" class="mb-3" :description="$gettext('Insert one or more telephone numbers to let Comunication Service of the UPC contact with you. Only the Comunication Service of the UPC can access this information.')">
                        <b-input-group v-for="(v, index) in $v.formData.telephone.$each.$iter" :key="'telephone.' + index" class="mb-2">
                            <b-form-input v-model="v.telephone.$model"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="success" @click="addTelephone()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteTelephone(index)" :disabled="formData.telephone.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group id="group-knowledgeArea" :label="'Area of Knowledge for the UPC experts directory'|translate" label-for="knowledgeArea" class="mb-3" :description="$gettext('Select the area/s of knowledge for the experts directory. This information will not be visible in the FUTUR website and only will be a guide for the UPC Communication Service in case it is necessary to contact experts of this area.')">
                        <b-input-group v-for="(v, index) in $v.formData.knowledgeArea.$each.$iter" :key="'knowledgeArea.' + index" class="mb-2">
                            <b-form-select :id="'knowledgeArea.' + index" v-model="v.id.$model" :state="v.$dirty ? !v.$error : null">
                                <b-form-select-option value="">{{ $gettext('-- Select a knowledge area --') }}</b-form-select-option>
                                <b-form-select-option :value="knowledgeArea.id" v-for="(knowledgeArea, index2) in knowledgeAreas" :key="'knowledgeArea.' + index + '.' + index2">{{ knowledgeArea.name[$language.current] }}</b-form-select-option>
                            </b-form-select>
                            <b-input-group-append>
                                <b-button variant="success" @click="addKnowledgeArea()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteKnowledgeArea(index)" :disabled="formData.knowledgeArea.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                            <b-form-invalid-feedback :id="'knowledgeArea.' + index"><translate>One knowledge area is required for UPC experts directory.</translate></b-form-invalid-feedback>
                        </b-input-group>
                    </b-form-group>

                    <br />
                    <b-button type="submit" variant="primary"><translate>Save</translate></b-button>
                    <b-button type="button" variant="success" class="ml-2" @click="openProfile()"><translate>View profile</translate></b-button>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';
    import { requiredIf } from 'vuelidate/lib/validators';

    export default {
        name: 'personal-corner-profile-data',
        props: ['item'],
        mixins: [validationMixin],
        data() {
            let componentData = {
                dataLoaded: false,
                relatedEntities: [],
                collaborativeNetworks: [],
                knowledgeAreas: [],
                imageFile: null,
                available: {
                    hIndex: true,
                    professionalCategory: true,
                    doctorate: true,
                    universityDegree: true,
                    postgraduateDegree: false
                },
                formData: {
                    showHindex: 0,
                    expertise: { },
                    showProfessionalCategoy: 0,
                    showDoctorate: 0,
                    showUniversityDegree: 0,
                    showPostgraduateDegree: 0,
                    relatedEntityMember: [],
                    collaborativeNetwork: [],
                    customLink: [],
                    knowledgeArea: [],
                    telephone: [],
                    expertsGuide: 0,
                    showFeatured: 1,
                    defaultFeatured: 0,
                    image: null,
                    deleteImage: 0
                },
                doPut: false,
                notAvailableMessage: this.$gettext('This information is not currently available in <a href="http://drac.upc.edu/" target="_blank">DRAC</a>. To publish you have to complete it in <a href="http://drac.upc.edu/" target="_blank">DRAC</a>.')
            };

            // Prepare fields with multiple languages
            Object.keys(this.$language.available).forEach(l => {
                componentData.formData.expertise[l] = '';
            });

            return componentData;
        },
        mounted() {
            // Get related data
            this.getRelatedEntities();
            this.getCollaborativeNetworks();
            this.getKnowledgeAreas();
            this.getExpertsGuideData();

            // Get current item values
            this.getCurrentItemValues();

            this.dataLoaded = true;
        },
        validations() {
            let rules = {
                    formData: {
                        showHindex: {},
                        expertise: { },
                        showProfessionalCategoy: {},
                        showDoctorate: {},
                        showUniversityDegree: {},
                        showPostgraduateDegree: {},
                        relatedEntityMember: { $each: { id: {} }},
                        collaborativeNetwork: {$each: { name: {}, link: {} }},
                        customLink: {$each: { title: {}, link: {} }},
                        telephone: {$each: { telephone: {} }},
                        knowledgeArea: { $each: {
                            id: { required: requiredIf(function() {
                                if (this.formData.expertsGuide == '1' && this.formData.knowledgeArea.find(ka => ka.id !== '') === undefined) return true;
                                else return false;
                             })} } },
                        expertsGuide: {},
                        showFeatured: {},
                        defaultFeatured: {}
                    }
                };

            // Prepare validation for fields with multiple languages
            Object.keys(this.$language.available).forEach(l => {
                rules.formData.expertise[l] = {
                    required: requiredIf(function() {
                        let require = false;

                        // Expertise is required in all languages
                        Object.keys(this.$language.available).forEach(la => {
                           if (la !== l && this.formData.expertise[la] != '') require = true;
                        });

                        // Expertise is required for experts guide
                        if (this.formData.expertsGuide == '1') require = true;

                        return require;
                    })
                };
            });

            return rules;
        },
        methods: {
            /**
             * Validates if a form field has a invalida value or not
             */
            validateState(name, language) {
                let dirty = false;
                let error = false;
                if (this.dataLoaded) {
                    dirty = (language === null ? this.$v.formData[name].$dirty : this.$v.formData[name][language].$dirty);
                    error =  (language === null ? this.$v.formData[name].$error : this.$v.formData[name][language].$error);
                }

                return dirty ? !error : null;
            },
            /**
             * Submits user data
             */
            submitProfileData() {
                this.$v.formData.$touch();
                // Check if form has errors
                if (!this.$v.formData.$anyError) {
                    // Execute operation related to expertsGuide value
                    if (!this.formData.expertsGuide === '0') {
                        // Delete experts guide information
                        this.axios.delete(this.apiURL + '/expertsGuide/' + this.item.id)
                            .then(() => {
                                this.updateItemData();
                            })
                            .catch(error => {
                                if (error.response.status !== 404) this.processError(error);
                                else this.updateItemData();
                            });
                    } else if (this.doPut) {
                        // Update experts guide information
                        let telephones = [];
                        this.formData.telephone.forEach(t => telephones.push(t.telephone));
                        let expertsGuideData = { id: this.item.id, status: this.formData.expertsGuide == '1' ? true : false, telephone: JSON.stringify(telephones) };
                        this.axios.put(this.apiURL + '/expertsGuide/' + this.item.id, this.cleanFormData(expertsGuideData))
                            .then(() => {
                                this.updateItemData();
                            })
                            .catch(error => {
                                this.processError(error);
                            });
                    } else {
                        // Create experts guide information
                        let telephones = [];
                        this.formData.telephone.forEach(t => telephones.push(t.telephone));
                        let expertsGuideData = { id: this.item.id, status: this.formData.expertsGuide == '1' ? true : false , observations: null, telephone: JSON.stringify(telephones) };
                        this.axios.post(this.apiURL + '/expertsGuide', this.cleanFormData(expertsGuideData))
                            .then(() => {
                                this.updateItemData();
                            })
                            .catch(error => {
                                this.processError(error);
                            });
                    }
                }
            },
            /**
             * Update item data
             */
            updateItemData() {
                // Modify item with new values
                let itemFields = this.item.secondaryFields;
                Object.keys(this.$language.available).forEach(lang => {
                    // Set image
                    // Delete image if checkbox is selected
                    if (this.formData.deleteImage == '1') delete itemFields[this.item.type + '_' + lang + '_image'];
                    else if (this.formData.image != '') itemFields[this.item.type + '_' + lang + '_image'] = [this.formData.image];
                    // Set featured
                    itemFields[this.item.type + '_' + lang + '_showFeatured'] = [this.formData.showFeatured];
                    // Set featured default
                    itemFields[this.item.type + '_' + lang + '_defaultFeatured'] = [this.formData.defaultFeatured];
                    // Set h index
                    if (this.available.hIndex) itemFields[this.item.type + '_' + lang + '_indexh_show'] = [this.formData.showHindex];
                    // Set expertise
                    itemFields[this.item.type + '_' + lang + '_expertise'] = this.formData.expertise[lang].split(',').map(e => e.trim());
                    // Set professional category
                    if (this.available.professionalCategory) itemFields[this.item.type + '_' + lang + '_professionalCategory_show'] = [this.formData.showProfessionalCategoy];
                    // Set doctorate
                    if (this.available.doctorate) itemFields[this.item.type + '_' + lang + '_doctorate_show'] = [this.formData.showDoctorate];
                    // Set university degree
                    if (this.available.universityDegree) itemFields[this.item.type + '_' + lang + '_universityDegree_show'] = [this.formData.showUniversityDegree];
                    // Set postgraduate
                    if (this.available.postgraduateDegree) itemFields[this.item.type + '_' + lang + '_postgraduateDegree_show'] = [this.formData.showPostgraduateDegree];
                    // Set related entities
                    let relatedEntityMember = [];
                    this.formData.relatedEntityMember.forEach(reId => {
                        if (reId.id !== '') {
                            let relatedEntity = this.relatedEntities.find(re => re.id == reId.id);
                            relatedEntityMember.push(relatedEntity.name + '##' + relatedEntity.link + '##' + relatedEntity.id);
                        }
                    });
                    itemFields[this.item.type + '_' + lang + '_relatedEntityMember'] = relatedEntityMember;
                    // Set collaborative networks
                    let collaborativeNetworks = [];
                    this.formData.collaborativeNetwork.forEach(cn => {
                        if (cn.link !== '') collaborativeNetworks.push(cn.link + '##' + cn.name);
                    });
                    itemFields[this.item.type + '_' + lang + '_collaborativeNetwork'] = collaborativeNetworks;
                    // Set custom links
                    let customLinks = [];
                    this.formData.customLink.forEach(cl => {
                        if (cl.link !== '') {
                            if (cl.title != '' && cl.title != undefined) customLinks.push(cl.title + '##' + cl.link);
                            else customLinks.push(cl.link);
                        }
                    });
                    itemFields[this.item.type + '_' + lang + '_customLink'] = customLinks;
                    // Set experts guide flag
                    itemFields[this.item.type + '_' + lang + '_expertsGuide'] = [this.formData.expertsGuide];
                    // Set knowledge areas
                    let knowledgeAreas = [];
                    this.formData.knowledgeArea.forEach(kaId => {
                        if (kaId.id !== '') {
                            let knowledgeArea = this.knowledgeAreas.find(ka => ka.id == kaId.id);
                            knowledgeAreas.push(knowledgeArea.name[lang] + '##' + kaId.id);
                        }
                    });
                    itemFields[this.item.type + '_' + lang + '_knowledgeArea'] = knowledgeAreas;
                    itemFields[this.item.type + '_' + lang + '_username'] = [this.$store.state.user.username];
                });

                // Update item
                this.axios.put(this.apiURL + '/items/' + this.item.id, { fields: itemFields } )
                    .then(() => {
                        this.updateItemAdditionalData();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Updates item additional data
             */
            updateItemAdditionalData() {
                // Update item additional data
                let itemAdditionalData = {};

                // Set image
                let value = {};
                if (this.formData.deleteImage == '1') Object.keys(this.$language.available).forEach(lang => { value[lang] = null });
                else if (this.formData.image != '') Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.image });
                itemAdditionalData['image'] = [value];

                // Set featured
                value = {};
                Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.showFeatured });
                itemAdditionalData['showFeatured'] = [value];

                // Set featured default
                value = {};
                Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.defaultFeatured });
                itemAdditionalData['defaultFeatured'] = [value];

                // Set h index
                if (this.available.hIndex) {
                    value = {};
                    Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.showHindex });
                    itemAdditionalData['indexh_show'] = [value];
                }

                // Set expertise
                let expertises = {};
                itemAdditionalData['expertise'] = [];
                let maxLength = 0;
                Object.keys(this.$language.available).forEach(lang => {
                    expertises[lang] = this.formData.expertise[lang].split(',').map(e => e.trim() )
                    if (expertises[lang].length > maxLength) maxLength = expertises[lang].length;
                });
                let i = 0;
                while (i < maxLength) {
                    let value = {};
                    Object.keys(this.$language.available).forEach(lang => {
                        if (i < expertises[lang].length) value[lang] = expertises[lang][i];
                        else value[lang] = '';
                    });
                    itemAdditionalData['expertise'].push(value);
                    i++;
                }

                // Set professional category
                if (this.available.professionalCategory) {
                    value = {};
                    Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.showProfessionalCategoy });
                    itemAdditionalData['professionalCategory_show'] = [value];
                }

                // Set doctorate
                if (this.available.doctorate) {
                    value = {};
                    Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.showDoctorate });
                    itemAdditionalData['doctorate_show'] = [value];
                }

                // Set university degree
                if (this.available.universityDegree) {
                    value = {};
                    Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.showUniversityDegree });
                    itemAdditionalData['universityDegree_show'] = [value];
                }

                // Set postgraduate
                if (this.available.postgraduateDegree) {
                    value = {};
                    Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.showPostgraduateDegree });
                    itemAdditionalData['postgraduateDegree_show'] = [value];
                }

                // Set related entities
                let relatedEntityMember = [];
                this.formData.relatedEntityMember.forEach(reId => {
                    if (reId.id !== '') {
                        let relatedEntity = this.relatedEntities.find(re => re.id == reId.id);
                        value = {};
                        Object.keys(this.$language.available).forEach(lang => { value[lang] = relatedEntity.name + '##' + relatedEntity.link + '##' + reId.id });
                        relatedEntityMember.push(value);
                    }
                });
                itemAdditionalData['relatedEntityMember'] = relatedEntityMember;

                // Set collaborative networks
                let collaborativeNetworks = [];
                this.formData.collaborativeNetwork.forEach(cn => {
                    if (cn.link !== '') {
                        value = {};
                        Object.keys(this.$language.available).forEach(lang => { value[lang] = cn.link + '##' + cn.name });
                        collaborativeNetworks.push(value);
                    }
                });
                itemAdditionalData['collaborativeNetwork'] = collaborativeNetworks;

                // Set custom links
                let customLinks = [];
                this.formData.customLink.forEach(cl => {
                    if (cl.link !== '') {
                        value = {};
                        if (cl.title != '' && cl.title != undefined) Object.keys(this.$language.available).forEach(lang => { value[lang] = cl.title + '##' + cl.link });
                        else Object.keys(this.$language.available).forEach(lang => { value[lang] = cl.link });
                        customLinks.push(value);
                    }
                });
                itemAdditionalData['customLink'] = customLinks;

                // Set experts guide flag
                value = {};
                Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.expertsGuide });
                itemAdditionalData['expertsGuide'] = [value];

                // Set knowledge areas
                let knowledgeAreas = [];
                this.formData.knowledgeArea.forEach(kaId => {
                    if (kaId.id !== '') {
                        let knowledgeArea = this.knowledgeAreas.find(ka => ka.id == kaId.id);
                        value = {};
                        Object.keys(this.$language.available).forEach(lang => { value[lang] = knowledgeArea.name[lang] + '##' + kaId.id });
                        knowledgeAreas.push(value);
                    }
                });
                itemAdditionalData['knowledgeArea'] = knowledgeAreas;

                // Update data
                this.axios.put(this.apiURL + '/itemsAdditionalData/' + this.item.id + '/updateFields', itemAdditionalData)
                    .then(() => {
                        this.showMessage(this.$gettext('Updated successfully'), this.$gettext('Your information has been updated successfully.'), 'success', false);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get all related entities
             */
            getRelatedEntities() {
                // Request related entities
                this.axios.get(this.apiURL + '/relatedEntities')
                    .then(response => {
                        // Save data
                        this.relatedEntities = response.data;
                    })
                    .catch(error => {
                        // Process error
                        this.processError(error);
                    });
            },
            /**
             * Adds a related entity
             */
            addRelatedEntity() {
                this.formData.relatedEntityMember.push({ id: '' });
            },
            /**
             * Delete a related entity
             */
            deleteRelatedEntity(index) {
                this.formData.relatedEntityMember.splice(index, 1);
            },
            /**
             * Get all collaborative networks
             */
            getCollaborativeNetworks() {
                // Request related entities
                this.axios.get(this.apiURL + '/collaborativeNetworks')
                    .then(response => {
                        // Save data
                        this.collaborativeNetworks = response.data;
                    })
                    .catch(error => {
                        // Process error
                        this.processError(error);
                    });
            },
            /**
             * Adds a collaborative network
             */
            addCollaborativeNetwork() {
                this.formData.collaborativeNetwork.push({ name: '', link: '' });
            },
            /**
             * Delete a collaborative network
             */
            deleteCollaborativeNetwork(index) {
                this.formData.collaborativeNetwork.splice(index, 1);
            },
            /**
             * Adds a custom  link
             */
            addCustomLink() {
                this.formData.customLink.push({ title: '', link: '' });
            },
            /**
             * Delete a custom link
             */
            deleteCustomLink(index) {
                this.formData.customLink.splice(index, 1);
            },
            /**
             * Adds a telephone
             */
            addTelephone() {
                this.formData.telephone.push({ telephone: ''});
            },
            /**
             * Delete a telephone
             */
            deleteTelephone(index) {
                this.formData.telephone.splice(index, 1);
            },
            /**
             * Get all knowledge areas
             */
            getKnowledgeAreas() {
                // Request related entities
                this.axios.get(this.apiURL + '/knowledgeAreas')
                    .then(response => {
                        // Save data
                        this.knowledgeAreas = response.data;
                    })
                    .catch(error => {
                        // Process error
                        this.processError(error);
                    });
            },
            /**
             * Adds a knowledge area
             */
            addKnowledgeArea() {
                this.formData.knowledgeArea.push({ id: '' });
            },
            /**
             * Delete a knowledge area
             */
            deleteKnowledgeArea(index) {
                this.formData.knowledgeArea.splice(index, 1);
            },
            /**
             * Opens a nwe window with user public profile
             */
            openProfile() {
                let url = this.getItemFieldValue(this.item, 'accessURL', this.$language.current, false);
                url = url.split('##')[0];
                window.open(url);
            },
            /**
             * Get current item values for form fields
             */
            getCurrentItemValues() {
                // Image
                this.formData.image = this.getItemFieldValue(this.item, 'image', this.$language.current, false);

                // H index
                let hIndex = this.getItemFieldValue(this.item, 'indexh', this.$language.current, false);
                if (hIndex === null) {
                    this.available.hIndex = false;
                    this.formData.showHindex = false;
                } else {
                    this.available.hIndex = true;
                    this.formData.showHindex = this.getItemFieldShowValue(this.item, 'indexh');
                }

                // Expertise areas
                Object.keys(this.$language.available).forEach(l => {
                    let expertise = this.getItemFieldValue(this.item, 'expertise', l, true);
                    if (expertise !== null) this.formData.expertise[l] = expertise.join(',');
                });

                // Professional category
                let professionalCategory = this.getItemFieldValue(this.item, 'professionalCategory', this.$language.current, false);
                if (professionalCategory === null) {
                    this.available.professionalCategory = false;
                    this.formData.showProfessionalCategoy = false;
                } else {
                    this.available.professionalCategory = true;
                    this.formData.showProfessionalCategoy = this.getItemFieldShowValue(this.item, 'professionalCategory');
                }

                // Doctorate
                let doctorate = this.getItemFieldValue(this.item, 'doctorate', this.$language.current, false);
                if (doctorate === null) {
                    this.available.doctorate = false;
                    this.formData.showDoctorate = false;
                } else {
                    this.available.doctorate = true;
                    this.formData.showDoctorate = this.getItemFieldShowValue(this.item, 'doctorate');
                }

                // University degree
                let universityDegree = this.getItemFieldValue(this.item, 'universityDegree', this.$language.current, false);
                if (universityDegree === null) {
                    this.available.universityDegree = false;
                    this.formData.showUniversityDegree = false;
                } else {
                    this.available.universityDegree = true;
                    this.formData.showUniversityDegree = this.getItemFieldShowValue(this.item, 'universityDegree');
                }

                // Postgraduate degree
                let postgraduateDegree = this.getItemFieldValue(this.item, 'postgraduateDegree', this.$language.current, false);
                if (postgraduateDegree === null) {
                    this.available.postgraduateDegree = false;
                    this.formData.showPostgraduateDegree = false;
                } else {
                    this.available.postgraduateDegree = true;
                    this.formData.showPostgraduateDegree = this.getItemFieldShowValue(this.item, 'postgraduateDegree');
                }

                // Related entity member
                let relatedEntityMember = this.getItemFieldValue(this.item, 'relatedEntityMember', this.$language.current, true);
                if (relatedEntityMember !== null) {
                    relatedEntityMember.forEach(rem => {
                        let value = rem.split('##');
                        this.formData.relatedEntityMember.push({id: value[2]});
                    });
                }
                if (this.formData.relatedEntityMember.length === 0) this.formData.relatedEntityMember.push({ id: '' });

                // Collaborative networks
                let collaborativeNetworks = this.getItemFieldValue(this.item, 'collaborativeNetwork', this.$language.current, true);
                if (collaborativeNetworks !== null) {
                    collaborativeNetworks.forEach(cn => {
                        let cnParts = cn.split('##');
                        this.formData.collaborativeNetwork.push({name: cnParts[1], link: cnParts[0]});
                    });
                }
                if (this.formData.collaborativeNetwork.length === 0) this.formData.collaborativeNetwork.push({ name: '', link: '' });

                // Custom links
                let customLinks = this.getItemFieldValue(this.item, 'customLink', this.$language.current, true);
                if (customLinks !== null) {
                    customLinks.forEach(cl => {
                        let clParts = cl.split('##');
                        if (clParts.length > 1) this.formData.customLink.push({title: clParts[0], link: clParts[1]});
                        else this.formData.customLink.push({title: '', link: clParts[0]});
                    });
                }
                if (this.formData.customLink.length === 0) this.formData.customLink.push({ name: '', link: '' });

                // Experts guide
                this.formData.expertsGuide = this.getItemFieldValue(this.item, 'expertsGuide', this.$language.current, false);
                if (this.formData.expertsGuide === null) this.formData.expertsGuide = '0';

                // Show featured
                this.formData.showFeatured = this.getItemFieldValue(this.item, 'showFeatured', this.$language.current, false);
                if (this.formData.showFeatured === null) this.formData.showFeatured = '0';

                // Default featured
                this.formData.defaultFeatured = this.getItemFieldValue(this.item, 'defaultFeatured', this.$language.current, false);
                if (this.formData.defaultFeatured === null) this.formData.defaultFeatured = '0';

                // Knowledge areas
                let knowledgeAreas = this.getItemFieldValue(this.item, 'knowledgeArea', this.$language.current, true);
                if (knowledgeAreas !== null) {
                    knowledgeAreas.forEach(ka => {
                        let id = ka.split('##')[1];
                        this.formData.knowledgeArea.push({id: id * 1});
                    });
                }
                if (this.formData.knowledgeArea.length === 0) this.formData.knowledgeArea.push({ id: '' });
            },
            /**
             * Get experts guide data
             */
            getExpertsGuideData() {
                // Request related entities
                this.axios.get(this.apiURL + '/expertsGuide/' + this.item.id)
                    .then(response => {
                        // Save data
                        JSON.parse(response.data.telephone).forEach(t => this.formData.telephone.push({ telephone: t }) );
                        if (this.formData.telephone.length === 0) this.formData.telephone.push({ telephone: '' });
                        this.doPut = true;
                    })
                    .catch(error => {
                        // Check if error is not found
                        if (error.response && error.response.status === 404) {
                            this.doPut = false;
                            this.formData.telephone.push({ telephone: '' });
                        }
                        else this.processError(error);
                    });
            },
            /**
             * Get field show property value
             */
            getItemFieldShowValue(item, fieldname) {
                // Prepare fieldname and return value
                fieldname = fieldname + '_show';
                let value = '0';

                // Check field in item
                if (this.item[fieldname] !== undefined) {
                    if (Array.isArray(this.item[fieldname])) value = this.item[fieldname][0];
                    else value = this.item[fieldname];
                } else {
                    if (Array.isArray(this.item.secondaryFields[this.item.type + '_' + this.$language.current + '_' + fieldname])) value = this.item.secondaryFields[this.item.type + '_' + this.$language.current + '_' + fieldname][0];
                    else value = this.item.secondaryFields[this.item.type + '_' + this.$language.current + '_' + fieldname];
                }

                // If null, set 0
                if (value === undefined) value = '0';

                return value;
            },
            /**
             * Uploads a file to server
             */
            uploadFile() {
                let formData = new FormData();
                formData.append('file', this.imageFile, this.imageFile.name);

                // Send upload request
                this.axios.post(this.apiURL + '/uploadFile?prefix=items', formData)
                    .then(response => {
                        // Save image file name
                        this.formData.image = response.data.path.replace('/items/', '');
                        this.$refs['imageInput'].reset();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        }
    }
</script>
