<!--
--------------------------------------------------------------------------
   PersonalCornerOrganizationProfile.vue
--------------------------------------------------------------------------

    Component to edit organization profile data

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner-organization-profile-data" class="container">
        <div class="row">
            <div class="col">
                <p v-translate>Fill in the form below to complete the information of your organization:</p>
                <b-form @submit.stop.prevent="submitProfileData" novalidate class="mt-4" v-if="dataLoaded">

                    <b-form-group id="group-image-file" :label="'Image'|translate" label-for="imageFile" :class="{ 'mb-1' : formData.image != null, 'mb-3' : formData.image == null}" :description="$gettext('Select an image file from your hard drive to include on your organization\'s page, or check the box to delete the current image. The file must be in JPG or PNG format, and the size should be 120 x 120 pixels.')">
                        <b-form-file v-model="imageFile" :placeholder="$gettext('Choose an image...')" @input="uploadFile" ref="imageInput"></b-form-file>
                    </b-form-group>

                    <b-form-checkbox v-model="formData.deleteImage" value="1" unchecked-value="0" class="mb-3" v-if="formData.image != null">
                        <translate>Delete current image</translate> (<a :href="$store.state.config.URL + '/images/items/' + formData.image" target="_blank">{{ formData.image }}</a>)
                    </b-form-checkbox>

                    <b-form-group id="group-collaborative-network" :label="'Collaborative networks'|translate" label-for="collaborativeNetwork" class="mb-3" :description="$gettext('Enter links for the collaborative networks to which your organization belongs. Select the network from the drop-down list and enter the link to your organization profile or page on the selected network in the text box.')">
                        <b-input-group v-for="(v, index) in $v.formData.collaborativeNetwork.$each.$iter" :key="'collaborativeNetwork.' + index" class="mb-2">
                            <b-input-group-prepend>
                                <b-form-select v-model="v.name.$model" style="width: 200px;">
                                    <b-form-select-option value="">{{ $gettext('-- Select a network --')}}</b-form-select-option>
                                    <b-form-select-option :value="collaborativeNetwork.name" v-for="(collaborativeNetwork, index2) in collaborativeNetworks" :key="'collaborativeNetwork.' + index + '.' + index2">{{ collaborativeNetwork.name }}</b-form-select-option>
                                </b-form-select>
                            </b-input-group-prepend>
                            <b-form-input v-model="v.link.$model" placeholder="http://www.example.com/user"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="success" @click="addCollaborativeNetwork()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteCollaborativeNetwork(index)" :disabled="formData.collaborativeNetwork.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group id="group-custom-link" :label="'Links of interest'|translate" label-for="customLink" class="mb-3" :description="$gettext('Enter the links of interest that you would like to include on your organization\'s page.')">
                        <b-input-group v-for="(v, index) in $v.formData.customLink.$each.$iter" :key="'customLink.' + index" class="mb-2">
                            <b-input-group-prepend>
                                <b-form-input v-model="v.title.$model" :placeholder="$gettext('Title (optional)')"></b-form-input>
                            </b-input-group-prepend>
                            <b-form-input v-model="v.link.$model" placeholder="http://www.example.com"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="success" @click="addCustomLink()"><i class="fas fa-plus"></i></b-button>
                                <b-button variant="danger" @click="deleteCustomLink(index)" :disabled="formData.customLink.length === 1"><i class="fas fa-minus"></i></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <br />
                    <b-button type="submit" variant="primary"><translate>Save</translate></b-button>
                    <b-button type="button" variant="success" class="ml-2" @click="openProfile()"><translate>View organization profile</translate></b-button>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';

    export default {
        name: 'personal-corner-organization-profile-data',
        props: ['item'],
        mixins: [validationMixin],
        data() {
            let componentData = {
                dataLoaded: false,
                collaborativeNetworks: [],
                imageFile: null,
                formData: {
                    collaborativeNetwork: [],
                    customLink: [],
                    image: null,
                    deleteImage: 0
                }
            };

            return componentData;
        },
        mounted() {
            // Get related data
            this.getCollaborativeNetworks();

            // Get current item values
            this.getCurrentItemValues();

            this.dataLoaded = true;
        },
        validations() {
            let rules = {
                formData: {
                    collaborativeNetwork: {$each: { name: {}, link: {} }},
                    customLink: {$each: { title: {}, link: {} }},
                }
            };

            return rules;
        },
        methods: {
            /**
             * Validates if a form field has a invalida value or not
             */
            validateState(name, language) {
                let dirty = false;
                let error = false;
                if (this.dataLoaded) {
                    dirty = (language === null ? this.$v.formData[name].$dirty : this.$v.formData[name][language].$dirty);
                    error =  (language === null ? this.$v.formData[name].$error : this.$v.formData[name][language].$error);
                }

                return dirty ? !error : null;
            },
            /**
             * Submits organization profile data
             */
            submitProfileData() {
                this.$v.formData.$touch();
                // Check if form has errors
                if (!this.$v.formData.$anyError) this.updateItemData();
            },
            /**
             * Update item data
             */
            updateItemData() {
                // Modify item with new values
                let itemFields = this.item.secondaryFields;
                Object.keys(this.$language.available).forEach(lang => {
                    // Set image
                    // Delete image if checkbox is selected
                    if (this.formData.deleteImage == '1') delete itemFields[this.item.type + '_' + lang + '_image'];
                    else if (this.formData.image != '') itemFields[this.item.type + '_' + lang + '_image'] = [this.formData.image];
                    // Set collaborative networks
                    let collaborativeNetworks = [];
                    this.formData.collaborativeNetwork.forEach(cn => {
                        if (cn.link !== '') collaborativeNetworks.push(cn.link + '##' + cn.name);
                    });
                    itemFields[this.item.type + '_' + lang + '_collaborativeNetwork'] = collaborativeNetworks;
                    // Set custom links
                    let customLinks = [];
                    this.formData.customLink.forEach(cl => {
                        if (cl.link !== '') {
                            if (cl.title != '' && cl.title != undefined) customLinks.push(cl.title + '##' + cl.link);
                            else customLinks.push(cl.link);
                        }
                    });
                    itemFields[this.item.type + '_' + lang + '_customLink'] = customLinks;
                });

                // Update item
                this.axios.put(this.apiURL + '/items/' + this.item.id, { fields: itemFields } )
                    .then(() => {
                        this.updateItemAdditionalData();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Updates item additional data
             */
            updateItemAdditionalData() {
                // Update item additional data
                let itemAdditionalData = {};

                // Set image
                let value = {};
                if (this.formData.deleteImage == '1') Object.keys(this.$language.available).forEach(lang => { value[lang] = null });
                else if (this.formData.image != '') Object.keys(this.$language.available).forEach(lang => { value[lang] = this.formData.image });
                itemAdditionalData['image'] = [value];

                // Set collaborative networks
                let collaborativeNetworks = [];
                this.formData.collaborativeNetwork.forEach(cn => {
                    if (cn.link !== '') {
                        value = {};
                        Object.keys(this.$language.available).forEach(lang => { value[lang] = cn.link + '##' + cn.name });
                        collaborativeNetworks.push(value);
                    }
                });
                itemAdditionalData['collaborativeNetwork'] = collaborativeNetworks;

                // Set custom links
                let customLinks = [];
                this.formData.customLink.forEach(cl => {
                    if (cl.link !== '') {
                        value = {};
                        if (cl.title != '' && cl.title != undefined) Object.keys(this.$language.available).forEach(lang => { value[lang] = cl.title + '##' + cl.link });
                        else Object.keys(this.$language.available).forEach(lang => { value[lang] = cl.link });
                        customLinks.push(value);
                    }
                });
                itemAdditionalData['customLink'] = customLinks;

                // Update data
                this.axios.put(this.apiURL + '/itemsAdditionalData/' + this.item.id + '/updateFields', itemAdditionalData)
                    .then(() => {
                        this.showMessage(this.$gettext('Updated successfully'), this.$gettext('Your organization information has been updated successfully.'), 'success', false);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get all collaborative networks
             */
            getCollaborativeNetworks() {
                // Request related entities
                this.axios.get(this.apiURL + '/collaborativeNetworks')
                    .then(response => {
                        // Save data
                        this.collaborativeNetworks = response.data;
                    })
                    .catch(error => {
                        // Process error
                        this.processError(error);
                    });
            },
            /**
             * Adds a collaborative network
             */
            addCollaborativeNetwork() {
                this.formData.collaborativeNetwork.push({ name: '', link: '' });
            },
            /**
             * Delete a collaborative network
             */
            deleteCollaborativeNetwork(index) {
                this.formData.collaborativeNetwork.splice(index, 1);
            },
            /**
             * Adds a custom  link
             */
            addCustomLink() {
                this.formData.customLink.push({ title: '', link: '' });
            },
            /**
             * Delete a custom link
             */
            deleteCustomLink(index) {
                this.formData.customLink.splice(index, 1);
            },
            /**
             * Opens a nwe window with user public profile
             */
            openProfile() {
                let url = this.getItemFieldValue(this.item, 'accessURL', this.$language.current, false);
                url = url.split('##')[0];
                window.open(url);
            },
            /**
             * Get current item values for form fields
             */
            getCurrentItemValues() {
                // Image
                this.formData.image = this.getItemFieldValue(this.item, 'image', this.$language.current, false);

                // Collaborative networks
                let collaborativeNetworks = this.getItemFieldValue(this.item, 'collaborativeNetwork', this.$language.current, true);
                if (collaborativeNetworks !== null) {
                    collaborativeNetworks.forEach(cn => {
                        let cnParts = cn.split('##');
                        this.formData.collaborativeNetwork.push({name: cnParts[1], link: cnParts[0]});
                    });
                }
                if (this.formData.collaborativeNetwork.length === 0) this.formData.collaborativeNetwork.push({ name: '', link: '' });

                // Custom links
                let customLinks = this.getItemFieldValue(this.item, 'customLink', this.$language.current, true);
                if (customLinks !== null) {
                    customLinks.forEach(cl => {
                        let clParts = cl.split('##');
                        if (clParts.length > 1) this.formData.customLink.push({title: clParts[0], link: clParts[1]});
                        else this.formData.customLink.push({title: '', link: clParts[0]});
                    });
                }
                if (this.formData.customLink.length === 0) this.formData.customLink.push({ name: '', link: '' });
            },
            /**
             * Uploads a file to server
             */
            uploadFile() {
                let formData = new FormData();
                formData.append('file', this.imageFile, this.imageFile.name);

                // Send upload request
                this.axios.post(this.apiURL + '/uploadFile?prefix=items', formData)
                    .then(response => {
                        // Save image file name
                        this.formData.image = response.data.path.replace('/items/', '');
                        this.$refs['imageInput'].reset();
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        }
    }
</script>
