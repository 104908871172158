<!--
--------------------------------------------------------------------------
   PersonalCorner.vue
--------------------------------------------------------------------------

    Component to build personal corner

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="personal-corner" class="view" v-if="showData">
        <b-tabs justified>
            <b-tab active lazy>
                <template #title>
                    <i class="fas fa-user"></i> <translate>User data</translate>
                </template>
                <personalCornerUserData></personalCornerUserData>
                <br />
                <personalCornerUserSearches></personalCornerUserSearches>
            </b-tab>
            <b-tab lazy v-if="profileItem !== null">
                <template #title>
                    <i class="fas fa-id-card"></i> <translate>Profile</translate>
                </template>
                <personalCornerProfile :item="profileItem"></personalCornerProfile>
            </b-tab>
            <b-tab lazy v-for="(organization, index) in organizationItems" :key="'organizationProfile.' + index">
                <template #title>
                    <i class="fas fa-home"></i> <translate>Profile</translate> {{ getItemFieldValue(organization, 'acronym', $language.current, false) }}
                </template>
                <personalCornerOrganizationProfile :item="organization"></personalCornerOrganizationProfile>
            </b-tab>
            <b-tab lazy v-if="profileItem !== null">
                <template #title>
                    <i class="fas fa-list"></i> <translate>Projects</translate>
                </template>
                <PersonalCornerProjects :item="profileItem"></PersonalCornerProjects>
            </b-tab>
            <b-tab lazy>
                <template #title>
                    <i class="fas fa-layer-group"></i> <translate>Simulations</translate>
                </template>
                <personalCornerUserSimulations></personalCornerUserSimulations>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import PersonalCornerUserData from '@/components/PersonalCornerUserData';
    import PersonalCornerUserSearches from '@/components/PersonalCornerUserSearches';
    import PersonalCornerProfile from '@/components/PersonalCornerProfile';
    import PersonalCornerOrganizationProfile from '@/components/PersonalCornerOrganizationProfile';
    import PersonalCornerUserSimulations from '@/components/PersonalCornerUserSimulations';
    import PersonalCornerProjects from '@/components/PersonalCornerProjects';

    export default {
        name: 'personal-corner',
        components: { PersonalCornerUserData, PersonalCornerUserSearches, PersonalCornerProfile, PersonalCornerUserSimulations, PersonalCornerOrganizationProfile, PersonalCornerProjects },
        data() {
            return {
                showData: false,
                profileItem: null,
                organizationItems: [],
                formData: {
                    query: '*:*',
                    facets: [],
                    sort: null,
                    page: {
                        pageIdx: 0,
                        pageSize: 1
                    }
                }
            }
        },
        created() {
            // Set base path
            this.$store.commit('setCurrentPath', '/personal_corner');
        },
        mounted() {
            // Clean breadcrumbs
            this.$store.commit('setBreadcrumbs', []);

            // Set metadata
            this.setMetadata(this.$gettext('Personal corner') + ' | ' + this.$gettext(this.$store.state.metadata.title), 'Personal corner', this.$gettext('personal corner') + ',' + this.$gettext(this.$store.state.metadata.keywords));

            // Verify if user is authenticated (only in production)
            if (process.env.NODE_ENV === 'production') {
                this.axios.get(this.apiURL + '/users/getUserAuth')
                    .then(response => {
                        // Store user data
                        if (this.$store.state.realUser === null) this.$store.commit('setUserData', response.data);

                        // Get user items
                        this.getUserItems();

                        // Show data
                        this.showData = true;
                    })
                    .catch(() => {
                        // Redirect to login endpoint
                        window.location = this.apiURL + '/users/login?returnUrl=' + encodeURI(location.href);
                    });
            } else this.getUserItems();
        },
        methods: {
            /**
             * Gets user items
             */
            getUserItems() {
                // Check if user has a profile in FUTUR
                this.formData.query = '+person_' + this.$language.current + '_username:"' + this.$store.state.user.username + '"';
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get item data and show profile tab
                        if (response.data.content.length === 1) {
                            this.profileItem = response.data.content[0];

                            // Check if user has organization profiles in FUTUR
                            let title = this.getItemFieldValue(this.profileItem, 'title', this.$language.current, false);
                            this.formData.query = '+(subtype:researchGroup OR subtype:department OR subtype:institute OR subtype:relatedEntity) +organization_' + this.$language.current + '_person_str:' + this.filterQuery(title) + '##*';
                            this.formData.page.pageSize = 5;
                            this.axios.post(this.apiURL + '/items/search', this.formData)
                                .then(response => {
                                    // Process items received
                                    response.data.content.forEach(item => {
                                        // Get organization persons and find current person
                                        let persons = this.getItemFieldValue(item, 'person', this.$language.current, true);
                                        let personData = persons.find(p => p.includes(title)).split('##');
                                        // Check if current person is a manager of the organization
                                        if (personData[2] === '1') this.organizationItems.push(item);
                                    });
                                })
                                .catch(error => {
                                    // Redirect to login endpoint
                                    this.processError(error);
                                });
                        }
                    })
                    .catch(error => {
                        // Redirect to login endpoint
                        this.processError(error);
                    });
            }
        }
    }
</script>